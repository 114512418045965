import React, { useState, useEffect } from "react";
import Utils from "../../utility";
import { useNavigate } from "react-router-dom";
import ClickOutside from "../../common/components/clickOutside";
import Pagination from "../../common/components/pagination";
import Dropdown from "../../common/components/dropdown";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import moment from "moment";
import { IoMdArrowDown, IoMdArrowUp } from "react-icons/io";

const CommercialUsage = ({
  setDate,
  setSelectTime,
  setSelectProcessedTime,
  selectTime,
  setProcessedDate,
  setDebouncedSearch,
  date,
  selectProcessedTime,
  processedDate,
  loading,
  list,
  totalPages,
  currentPage,
  handlePageChange,
  handleFirstPage,
  handleLastPage,
  report,
  setCommercialUsed,
  commerciallyUsed,
  selectedVersion,
  setSelectedVersion,
  versions,
  setCurrentPage,
  sortOrder,
  setSortOrder
}) => {
  const [open, setOpen] = useState(false);
  const [openProcessed, setOpenProcessed] = useState(false);
  const [searchClaim, setSearchClaim] = useState("");
  const navigate = useNavigate();

  const [range, setRange] = useState({
    startDate: null,
    endDate: new Date(""),
    key: "date"
  });

  const [rangeProcessed, setRangeProcessed] = useState({
    startDate: null,
    endDate: new Date(""),
    key: "selection"
  });

  const handleDateSelect = (ranges) => {
    const { startDate, endDate } = ranges.date;

    const formattedStartDate = Utils.formatDateYear(startDate);
    const formattedEndDate = Utils.formatDateYear(endDate);

    setRange({
      ...range,
      startDate: startDate,
      endDate: endDate
    });

    setDate((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }));
  };

  const handleSelect = (value) => {
    setSelectTime(value);
    if (value === "Custom") {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleSelectProcessed = (value) => {
    setSelectProcessedTime(value);
    if (value === "Custom") {
      setOpenProcessed(true);
    } else {
      setOpenProcessed(false);
    }
  };

  const handleClearFilters = () => {
    setSelectTime("All");

    setRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "date"
    });
    setSearchClaim("");
    setDebouncedSearch("");
    setSelectProcessedTime("All");
    setSelectedVersion("All");
    setCommercialUsed("All");
  };

  const handleDateSelectProcessed = (ranges) => {
    const { startDate, endDate } = ranges.selection;

    const formattedStartDate = Utils.formatDate(startDate);
    const formattedEndDate = Utils.formatDate(endDate);

    setRangeProcessed({
      ...rangeProcessed,
      startDate: ranges.selection.startDate,
      endDate: ranges.selection.endDate
    });

    setProcessedDate((prev) => ({
      ...prev,
      startDate: formattedStartDate,
      endDate: formattedEndDate
    }));
  };
  const handleSort = () => {
    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };
  const handleSearch = (e) => {
    setSearchClaim(e.target.value);
  };

  useEffect(() => {
    if (searchClaim !== "") {
      const handler = setTimeout(() => {
        setDebouncedSearch(searchClaim);
        setCurrentPage(1);
      }, 600);

      return () => clearTimeout(handler);
    } else {
      setDebouncedSearch("");
    }

    // eslint-disable-next-line
  }, [searchClaim]);

  return (
    <div>
      {" "}
      <div className="flex gap-3">
        <div className="flex flex-wrap items-center gap-2">
          <img
            onClick={() => navigate("/claim-list")}
            src="/images/left-arrow.svg"
            alt=""
            className="cursor-pointer"
          />
          <span className="font-InterBold text-ft25-30 text-black-10">
            Commercial Usage
          </span>
        </div>
        <div className="ml-3 flex h-10 w-55 items-center gap-2 rounded-lg border  border-grey-10 bg-white p-3">
          <img
            src="/images/search-safe-guard.svg"
            alt="searchIcon"
            className="size-5 opacity-60"
          />
          <input
            type="text"
            placeholder="Claim Search"
            value={searchClaim}
            onChange={handleSearch}
            className="w-full bg-transparent font-InterRegular text-ft14-17 text-black-0 placeholder:text-grey-20 focus:outline-none"
          />
        </div>
        <div className="flex gap-2 pl-5">
          <ClickOutside isOpen={open} onClickOutside={() => setOpen(false)}>
            <div className="relative">
              <Dropdown
                width={"min-w-30"}
                text={"Date"}
                value={selectTime}
                list={["All", "Last 14 days", "Previous month", "Custom"]}
                handleSelect={handleSelect}
                setOpenCalendar={setOpen}
                startDate={date?.startDate}
                endDate={date?.endDate}
              />
              {open && (
                <div className="absolute z-[999]">
                  <DateRange
                    ranges={[range]}
                    onChange={handleDateSelect}
                    className="rounded-md border border-grey-30"
                    showDateDisplay={false}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </div>
          </ClickOutside>
          <ClickOutside
            isOpen={openProcessed}
            onClickOutside={() => setOpenProcessed(false)}
          >
            <div className="relative">
              <Dropdown
                width={"min-w-30"}
                text={"Processed"}
                value={selectProcessedTime}
                list={["All", "Last 14 days", "Previous month", "Custom"]}
                handleSelect={handleSelectProcessed}
                setOpenCalendar={setOpenProcessed}
                startDate={processedDate?.startDate}
                endDate={processedDate?.endDate}
              />
              {openProcessed && (
                <div className="absolute z-[999]">
                  <DateRange
                    ranges={[rangeProcessed]}
                    onChange={handleDateSelectProcessed}
                    className="rounded-md border border-grey-30"
                    showDateDisplay={false}
                    showMonthAndYearPickers={false}
                  />
                </div>
              )}
            </div>
          </ClickOutside>
          <Dropdown
            width={"min-w-31"}
            text={"Commercial Used"}
            value={commerciallyUsed}
            list={["All", "Yes", "No"]}
            handleSelect={setCommercialUsed}
            setCurrentPage={setCurrentPage}
          />
          <Dropdown
            width={"min-w-31"}
            text={"Version"}
            value={selectedVersion}
            list={versions}
            handleSelect={setSelectedVersion}
            setCurrentPage={setCurrentPage}
          />
        </div>
        <div
          className="flex cursor-pointer items-center gap-1"
          onClick={handleClearFilters}
        >
          <span className="font-InterRegular text-ft14-17 text-blue-10">
            Clear
          </span>
          <img src="/images/clear.svg" alt="" />
        </div>
      </div>
      <div className="mt-4 flex gap-6">
        <div className="flex items-center gap-2 rounded-lg border border-grey-10 bg-grey-10 bg-opacity-20 p-3">
          <span className="font-InterRegular text-ft16-24">Total:</span>
          <span className="font-InterSemiBold text-ft16-24">
            {report?.totalDocuments || 0}
          </span>
        </div>
        <div className="flex items-center gap-2 rounded-lg border border-grey-10 bg-grey-10 bg-opacity-20 p-3">
          <span className="font-InterRegular text-ft16-24">
            Successfully Processed:
          </span>
          <span className="font-InterSemiBold text-ft16-24">
            {report?.successfullyProcessed || 0}
          </span>
        </div>
        <div className="flex items-center gap-2 rounded-lg border border-grey-10 bg-grey-10 bg-opacity-20 p-3">
          <span className="font-InterRegular text-ft16-24">
            Commercially Used:
          </span>
          <span className="font-InterSemiBold text-ft16-24">
            {report?.commercialUsageCount || 0}
          </span>
        </div>
      </div>
      {loading ? (
        <div className="flex h-80vh items-center justify-center">
          <div className="loader "></div>
        </div>
      ) : list?.length > 0 ? (
        <div>
          <div className="mt-4 max-h-75vh w-full  overflow-y-auto">
            <table className="w-full">
              <thead>
                <tr className="sticky top-0  z-10 w-full bg-white font-InterBold text-ft10-18.15 text-black-10">
                  <td className="pb-2.5 pl-2.5">Claim Number</td>
                  <td>Version</td>
                  <td>Contract Number</td>
                  <td>VIN</td>
                  <td>
                    <div
                      onClick={handleSort}
                      className="flex cursor-pointer gap-1"
                    >
                      <span className="font-InterBold text-ft10-18.15  text-black-10">
                        Created On
                      </span>
                      {sortOrder === "asc" ? (
                        <IoMdArrowDown />
                      ) : (
                        <IoMdArrowUp />
                      )}
                    </div>
                  </td>
                  <td>
                    <div className="flex cursor-pointer gap-1">
                      <span className="font-InterBold text-ft10-18.15  text-black-10">
                        Processed On
                      </span>
                    </div>
                  </td>

                  <td>Commercially Used</td>
                  <td>Claim status</td>
                </tr>
              </thead>
              <tbody>
                {list.map((item, idx) => {
                  return (
                    <tr
                      key={idx}
                      className="cursor-pointer border-t border-grey-30 hover:bg-grey-10 hover:bg-opacity-[.2]"
                    >
                      <td className="w-10per py-3.5 pl-2.5 font-InterRegular text-ft10-18.15 text-black-10">
                        {item.claimNumber}
                      </td>
                      <td className="w-10per font-InterRegular text-ft15-18 text-black-10">
                        v{item?.version}
                      </td>
                      <td className="w-10per font-InterRegular text-ft15-18 text-black-10">
                        {item.contractNumber}
                      </td>

                      <td className="w-15per font-InterRegular text-ft15-18 text-black-10">
                        {item.vin}
                      </td>
                      <td className="w-15per font-InterRegular text-ft15-18 text-black-10">
                        {moment(item.createdDateTime).format(
                          "DD MMM YYYY h:mm A"
                        )}
                      </td>
                      <td className="w-15per font-InterRegular text-ft15-18 text-black-10">
                        {item.processedOn
                          ? moment(item.processedOn).format(
                              "DD MMM YYYY h:mm A"
                            )
                          : "-"}
                      </td>

                      <td className="w-15per font-InterRegular text-ft15-18 text-black-10">
                        {item.commercialUsageSystemResult
                          ?.isUsedCommercially === "y"
                          ? "Yes"
                          : item.commercialUsageSystemResult
                                ?.isUsedCommercially === "n"
                            ? "No"
                            : Utils.camelToTitleCase(
                                item.commercialUsageSystemResult
                                  ?.isUsedCommercially || item?.comparisonResult
                              )}
                      </td>

                      <td className="w-15per">
                        {item?.claimStatus && (
                          <div
                            className={`${
                              item.claimStatus.toLowerCase() === "approved"
                                ? " bg-green-10 bg-opacity-[.2] text-green-10"
                                : item.claimStatus?.toLowerCase() === "denied"
                                  ? "bg-red-20 text-red-10"
                                  : "bg-grey-100 bg-opacity-[.5] text-black-20 text-opacity-[.8]"
                            } w-max rounded-full  px-2.5 py-1 font-InterMedium text-ft14-17 `}
                          >
                            {Utils.formatText(item.claimStatus)}
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            handleFirstPage={handleFirstPage}
            handleLastPage={handleLastPage}
            handlePageChange={handlePageChange}
          />
        </div>
      ) : (
        <div className="flex h-80 items-center justify-center">
          <span className="font-InterBold text-ft24-32 text-black-0">
            No data found
          </span>
        </div>
      )}
    </div>
  );
};

export default CommercialUsage;
